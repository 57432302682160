import wrapWithProvider from './wrapWithProvider';
import { gaPageView, mpPageView } from 'utils/analytics';
import { navigate } from 'gatsby';

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (prevLocation && prevLocation.pathname !== location.pathname) {
    gaPageView(location.pathname);
    mpPageView();
  }

  if (location.href.includes('/careers/?ashby_jid')) {
    const params = new URLSearchParams(location.search);
    const id = params.get('ashby_jid');
    navigate(`/careers/${id}/${location.search}`);
  }
};

export const wrapRootElement = wrapWithProvider;
