import React from 'react';
import ReactGA from 'react-ga';
import isJson from 'utils/isJson';

const OtherEventType = 8;

export const getUTM = () => {
  if (window.location.search) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    return {
      utm_campaign: urlParams.get('utm_campaign') || '',
      utm_source: urlParams.get('utm_source') || '',
      utm_medium: urlParams.get('utm_medium') || '',
      utm_term: urlParams.get('utm_term') || '',
      utm_content: urlParams.get('utm_content') || '',
    };
  }
  return {};
};

export default class Analytics {
  constructor(args) {
    Object.assign(this, args);
    this.scripts = scripts(args);
  }

  get mparticle() {
    return this.scripts.mparticle;
  }
}

export function gaPageView(path) {
  ReactGA.set({ page: path });
  ReactGA.pageview(path);
}

export function mpFormSubmitted(event) {
  if (!isJson(event.data)) return;
  const messageData = JSON.parse(event.data);
  if (messageData.event === 'Form Submitted' && window.mParticle) {
    const identityRequest = { userIdentities: { email: messageData.email } };
    const identityCallback = (result) => {
      if (result.getUser()) {
        const user = result.getUser();
        if (messageData.first_name) {
          user.setUserAttribute('$FirstName', messageData.first_name);
        }
        if (messageData.last_name) {
          user.setUserAttribute('$LastName', messageData.last_name);
        }
        if (messageData.company) {
          user.setUserAttribute('Company', messageData.company);
        }
        if (messageData.job_title) {
          user.setUserAttribute('Job Title', messageData.job_title);
        }
        if (messageData.role) {
          user.setUserAttribute('Role', messageData.role);
        }
        window.mParticle.logEvent('Form Submitted', mParticle.EventType.Other, {
          form_action: messageData.form_action,
          first_name: messageData.first_name,
          last_name: messageData.last_name,
          email: messageData.email,
          inquiry_type: messageData.inquiry_type,
          partner_inquiry_type: messageData.partner_inquiry_type,
          role: messageData.role,
          message: messageData.message,
          utm_campaign: messageData.utm_campaign,
          utm_content: messageData.utm_content,
          utm_medium: messageData.utm_medium,
          utm_source: messageData.utm_source,
          utm_term: messageData.utm_term,
        });
      }
    };
    window.mParticle.Identity.login(identityRequest, identityCallback);
  }
}

export function mpPageView() {
  if (window.mParticle) {
    const path = window.location.pathname.toString();
    const getCategory = (path) => {
      if (path.includes('/platform/detail')) return 'Platform Detail Page';
      if (path.includes('/platform/')) return 'Platform Pillar Page';
      if (path.includes('/solutions/')) return 'Solutions Page';
      if (path.includes('/integration/')) return 'Integration Page';
      if (path.includes('/customers/')) return 'Customer Use Case';
      if (path.includes('/resources/')) return 'Resources';
      if (path.includes('/news/')) return 'News';
      if (path.includes('/blog/')) return 'Blog Post';
      if (path.includes('/lpg/')) return 'Landing Page';
      if (path === '/') return 'Home';
      return 'Other';
    };
    mParticle.logPageView(
      'Marketing Page View',
      { anchor: path, category: getCategory(path) },
      { 'Google.Page': path }
    );
  }
}

export function logShare(network) {
  if (window.mParticle) {
    let title = document.title;
    let URL = window.location.href.toString();
    let utm_campaign, utm_medium, utm_source;

    if (window.location.search) {
      let queryString = window.location.search;
      let urlParams = new URLSearchParams(queryString);
      utm_campaign = urlParams.get('utm_campaign');
      utm_source = urlParams.get('utm_source');
      utm_medium = urlParams.get('utm_medium');
    }

    const event_props = {
      network: network,
      title: title,
      url: URL,
    };

    if (utm_campaign) {
      event_props.utm_campaign = utm_campaign;
    }
    if (utm_source) {
      event_props.utm_source = utm_source;
    }
    if (utm_medium) {
      event_props.utm_medium = utm_medium;
    }

    window.mParticle.logEvent(
      'Share Button Clicked',
      window.mParticle.EventType.Social,
      event_props
    );
  }
}

export function mpContentDownloaded() {
  if (window.mParticle) {
    const title = document.title;
    const URL = window.location.href.toString();
    let utm_campaign, utm_source, utm_medium, utm_content, utm_term;

    if (window.location.search) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      utm_campaign = urlParams.get('utm_campaign');
      utm_source = urlParams.get('utm_source');
      utm_medium = urlParams.get('utm_medium');
      utm_content = urlParams.get('utm_content');
      utm_term = urlParams.get('utm_term');
    }
    const event_props = {
      title: title,
      url: URL,
      ...getUTM(),
    };

    if (utm_campaign) {
      event_props.utm_campaign = utm_campaign;
    }
    if (utm_source) {
      event_props.utm_source = utm_source;
    }
    if (utm_medium) {
      event_props.utm_medium = utm_medium;
    }
    if (utm_content) {
      event_props.utm_content = utm_content;
    }
    if (utm_term) {
      event_props.utm_term = utm_term;
    }

    window.mParticle.logEvent('Content Downloaded', window.mParticle.EventType.Other, event_props);
  }
}

export function linkedImageClicked(props) {
  if (window.mParticle && props.primary.content_tracking === 'Yes') {
    const URL = window.location.href.toString();
    const title = document.title;

    console.log(props.primary.content_tracking);

    const event_props = {
      alt_text: props.primary.image.alt,
      url: URL,
      title: title,
    };

    window.mParticle.logEvent('CTA banner clicked', window.mParticle.EventType.Other, event_props);
    console.log(event_props);
  }
}

export function mpUrlValid(url) {
  if (window.mParticle) {
    const URL = window.location.href.toString();
    const title = document.title;

    const event_props = {
      url: URL,
      title: title,
      value: url,
    };

    window.mParticle.logEvent(
      'Integration Streamliner Sent',
      window.mParticle.EventType.Other,
      event_props
    );
  }
}

export function annoucementLog() {
  if (window.mParticle) {
    const URL = window.location.href.toString();
    const title = document.title;
    const event_props = {
      url: URL,
      title: title,
    };

    window.mParticle.logEvent('Home Banner Clicked', window.mParticle.EventType.Other, event_props);
  }
}

export function sandboxFormOpened() {
  if (window.mParticle) {
    window.mParticle.logEvent(
      'Sandbox Form Opened',
      window.mParticle.EventType?.Other || OtherEventType,
      {}
    );
  }
}

export function sandboxFormSubmitted(data) {
  if (window.mParticle) {
    const event_props = {
      ...data,
      referrer: window.location.href.toString(),
      ...getUTM(),
    };

    window.mParticle.logEvent(
      'Sandbox Form Submitted',
      window.mParticle.EventType?.Other || OtherEventType,
      event_props
    );
  }
}

export function getInTouchClicked() {
  if (window.mParticle) {
    window.mParticle.logEvent(
      'Get in touch clicked',
      window.mParticle.EventType?.Other || OtherEventType,
      {}
    );
  }
}
