import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { mpFormSubmitted } from 'utils/analytics';

export default class Analytics extends PureComponent {
  static propTypes = {
    location: PropTypes.object,
  };

  componentDidMount() {
    window.addEventListener('message', mpFormSubmitted);
  }

  render() {
    return null;
  }
}
