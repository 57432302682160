import { extendObservable, makeAutoObservable } from 'mobx';
import * as prismic from '@prismicio/client';

import getCookie from 'utils/get-cookie';
import { isFeature } from 'utils/isFeature';
import { getApi } from 'utils/query-prismic';

const VIEWED_BANNER_COOKIE = 'Prismic-ViewedBanner';
const SENDED_PLATFORM_FORM_COOKIE = 'Prismic-PlatformForm';

export default class UIStore {
  // Header
  whiteHeader = false; // header with white  background and dark text color
  headerHover = false; // change state on hover
  headerVisible = false; // hide header
  simpleHeader = false; // only logo header
  mobileNavOpen = false;
  visibleSubNav = null;

  simpleFooter = false; // state for landing pages (simple footer)
  darkFooter = false; // different type of footer
  footerNavigationInvisible = false; // hide footer  navigation links
  footerVisible = true; //  remove the entire footer
  showFooterCTA = true;

  announcementBannerVisible = false;

  announcementBanner = null;
  showPlatformForm = false;

  constructor(state = {}) {
    makeAutoObservable(this);
    extendObservable(this, state);
  }

  setWhiteHeader = (value) => {
    this.whiteHeader = value;
  };

  checkForAnnouncement = async () => {
    const client = await getApi();
    const getLatestBanner = await client.get({
      predicates: [prismic.predicate.at('document.type', 'announcement_bar')],
      orderings: {
        field: 'document.last_publication_date',
        direction: 'desc',
      },
      pageSize: 1,
      fetchLinks: ['article.article_type', 'article.external_url', 'landing_page.top_level_domain'],
    });

    const banner = getLatestBanner.results[0];
    if (!banner) {
      return;
    }
    const readId = getCookie(VIEWED_BANNER_COOKIE);

    if (
      // Banner has not expired yet
      isFeature(banner.data.expires) &&
      // User has not closed the banner yet
      (!readId || readId !== banner.id)
    ) {
      this.setAnnouncementBanner(banner);
      this.setAnnouncementBannerVisible(true);
    }
  };

  setAnnouncementBanner = (banner) => {
    this.announcementBanner = banner;
  };

  setAnnouncementBannerVisible = (value) => {
    this.announcementBannerVisible = value;
  };

  setFooterNavigationInvisible = (value) => {
    this.footerNavigationInvisible = value;
  };

  setDarkFooter = (value) => {
    this.darkFooter = value;
  };

  setFooterVisible = (value) => {
    this.footerVisible = value;
  };

  setShowFooterCTA = (value) => {
    this.showFooterCTA = value;
  };

  setHeaderVisible = (value) => {
    this.headerVisible = value;
  };

  setSimpleHeader = (value) => {
    this.simpleHeader = value;
  };

  setSimpleFooter = (value) => {
    this.simpleFooter = value;
  };

  setAnnouncementBannerCookie = () => {
    document.cookie = `${VIEWED_BANNER_COOKIE}=${this.announcementBanner.id}`;
  };

  setPlatformFormCookie = (email) => {
    document.cookie = `${SENDED_PLATFORM_FORM_COOKIE}=${email}`;
  };

  setShowPlatformForm = () => {
    return (this.showPlatformForm = !!getCookie(SENDED_PLATFORM_FORM_COOKIE));
  };

  enterHeader = () => {
    this.headerHover = true;
  };

  leaveHeader = () => {
    this.headerHover = false;
  };

  setVisibleSubNav = (value) => {
    this.visibleSubNav = value;
  };

  toggleMobileNav = (value) => {
    this.mobileNavOpen = value;
    this.setAnnouncementBannerVisible(!value);
  };

  setSubNavHeight = (value) => {
    this.subNavHeight = value;
  };
}
