import React from 'react';
/**
 * This file contains configuration for `gatsby-plugin-prismic-previews` to
 * support preview sessions from Prismic with drafts and unpublished documents.
 *
 * @see https://prismic.io/docs/technologies/previews-gatsby
 */

import { linkResolver } from './prismic-link-resolver';

const About = React.lazy(() => import('pages/about-us'));
const Careers = React.lazy(() => import('templates/careers'));
const CertifiedPartners = React.lazy(() => import('pages/certified-solution-partners'));
const ConnectedPodcast = React.lazy(() => import('pages/connected-podcast'));
const Customers = React.lazy(() => import('pages/customers'));
const CertifiedDataPartners = React.lazy(() => import('pages/data-partners'));
const HomePersona = React.lazy(() => import('pages/index'));
const NewsletterArchive = React.lazy(() => import('pages/newsletters'));
const PartnerHub = React.lazy(() => import('pages/partner'));
const PeriodicTable = React.lazy(() => import('pages/periodictable'));
const Pricing = React.lazy(() => import('pages/pricing'));
const Search = React.lazy(() => import('pages/search'));
const SdkSearch = React.lazy(() => import('pages/search/sdk-search'));
const UseCasesSearch = React.lazy(() => import('pages/search/use-cases'));
const Security = React.lazy(() => import('pages/security'));
const ServicesPricing = React.lazy(() => import('pages/services-pricing'));
const Partners = React.lazy(() => import('pages/technology-partners'));
const TrustCenter = React.lazy(() => import('pages/trust-center'));
const UseCases = React.lazy(() => import('pages/use-cases'));

const Article = React.lazy(() => import('templates/article'));
const ArticleOverview = React.lazy(() => import('templates/article-overview'));
const Category = React.lazy(() => import('templates/category'));
const Collection = React.lazy(() => import('templates/collection'));
const Confirmation = React.lazy(() => import('templates/confirmation'));
const ConnectedAwards = React.lazy(() => import('templates/connected-awards'));
const ContactTemplate = React.lazy(() => import('templates/contact'));
const Cookie = React.lazy(() => import('templates/cookie'));
const Events = React.lazy(() => import('templates/events'));
const Integration = React.lazy(() => import('templates/integration'));
const Integrations = React.lazy(() => import('templates/integrations'));
const LandingPage = React.lazy(() => import('templates/landing-page'));
const Legal = React.lazy(() => import('templates/legal'));
const NewsletterDetail = React.lazy(() => import('templates/newsletters'));
const PaidLandingPage = React.lazy(() => import('templates/paid-landing-page'));
const Personas = React.lazy(() => import('templates/personas'));
const PlatformDetailInner = React.lazy(() => import('templates/platform-detail-inner'));
const Privacy = React.lazy(() => import('templates/privacy'));
const PlatformDetail = React.lazy(() => import('templates/solution-detail'));
const SolutionVertical = React.lazy(() => import('templates/solution-vertical'));
const SolutionsPartner = React.lazy(() => import('templates/solutions-partner'));
const Tag = React.lazy(() => import('templates/tag'));
const UseCasesDetail = React.lazy(() => import('templates/use-cases-detail'));
const Job = React.lazy(() => import('templates/job'));

/**
 * Prismic preview configuration for each repository in your app. This set of
 * configuration objects will be used with the `PrismicPreviewProvider`
 * higher order component.
 *
 * If your app needs to support multiple Prismic repositories, add each of
 * their own configuration objects here as additional elements.
 *
 */
export const repositoryConfigs = [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO,
    linkResolver,
    componentResolver: {
      about_page: About,
      careers_page: Careers,
      home_page: HomePersona,
      certified_data_partners: CertifiedPartners,
      connected_podcast: ConnectedPodcast,
      customers_page: Customers,
      certified_data_partners: CertifiedDataPartners,
      newsletter_archive: NewsletterArchive,
      partner_hub: PartnerHub,
      periodic_table_page: PeriodicTable,
      pricing_page: Pricing,
      security: Security,
      services_pricing: ServicesPricing,
      partners_pag: Partners,
      trust_center: TrustCenter,
      use_cases: UseCases,
      search: Search,
      sdk_search: SdkSearch,
      use_case_search: UseCasesSearch,
      article: Article,
      article_overview_page: ArticleOverview,
      article_category: Category,
      article_collection: Collection,
      confirmation_page: Confirmation,
      connected_awards: ConnectedAwards,
      contact_page_template: ContactTemplate,
      cookie_page: Cookie,
      events_page: Events,
      integration_page_template: Integration,
      integrations_page: Integrations,
      landing_page: LandingPage,
      legal_page: Legal,
      newsletter_detail: NewsletterDetail,
      paid_landing_page: PaidLandingPage,
      personas: Personas,
      product_detail_page: PlatformDetailInner,
      privacy_page: Privacy,
      solution: PlatformDetail,
      solution_vertical: SolutionVertical,
      solutions_partner: SolutionsPartner,
      tag: Tag,
      use_cases_detail: UseCasesDetail,
      job: Job,
    },
  },
];
