import React from 'react';
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import { Provider } from 'mobx-react';
import Analytics from './src/components/analytics';
import Store from './src/store';
import { repositoryConfigs } from './src/utils/prismicPreviews';

const store = new Store();

export default ({ element }) => (
  <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
    <Provider {...store}>
      <>
        <Analytics />
        {element}
      </>
    </Provider>
  </PrismicPreviewProvider>
);
