import cookie from 'cookie';

export default function getCookie(name) {
  if (typeof document === 'undefined') {
    return null;
  }

  const cookies = cookie.parse(document.cookie);

  return cookies[name];
}
